import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import { orange, white } from 'src/styles/colors'

export const Section = styled.section`

  .bg-text {
    background: ${orange.extra};
    color: ${white};
    width: fit-content;
  }

  .scroll-link {
    bottom: 20px;
    fill: orange;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }

  p {
    margin-top: 23px;
    @media ${device.tablet} {
      margin-top: 30px;
    }

    @media ${device.desktopLG} {
      margin-top: 35px;
    }
  }

  min-height: 348px;

  @media ${device.tablet} {
    min-height: 536px;
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/768-ga/image.webp') no-repeat transparent;
    background-position: 100% 50%;
  }

  @media ${device.desktopLG} {
    min-height: 646px;
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/1024-ga/image.webp') no-repeat transparent;
    background-position: 100% 50%;
  }

  @media ${device.desktopXL} {
    min-height: 650px;
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/1440-ga/image.webp') no-repeat transparent;
    background-position: bottom right;
  }

  @media ${device.desktopXXXL} {
    min-height: 650px;
    background-size: contain;
    background-position: right;
  }
`
