import React, { useState, useLayoutEffect, useEffect } from 'react'
import Layout from 'src/layouts/BaseLayout'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'

import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'

import Hero from './sections/hero/_index'
import DollarAccount from '../sections/dollar-account/_index'
import SecureYourCard from '../sections/assure-your-card/_index'
import GlobalAccountAdvantages from '../sections/global-account-advantages/_index'
import FacilityAndSecurity from '../sections/facility-and-security/_index'
import HowToOpenYourGlobalAccount from '../sections/how-to-open-your-global-account/_index'
import InternationalGiftCards from '../sections/international-gift-cards/_index'
import YourReaisAreWorthMoreDollars from '../sections/your-reais-are-worth-more-dollars/_index'
import GlobalShopping from '../sections/global-shopping/_index'

import Faq from '../sections/faq/_index'
import { Disclaimer } from '../sections/disclaimer/_index'

import QrCode from './assets/images/qrcode-global-account.png'

import pageContextJSON from './pageContext.json'
import { Content } from './style'

const WIDTH_MD = 768

const GlobalAccount = () => {
  const width = useWidth(200)
  const domReady = useDomReady()

  const oneLink = 'https://bancointer.go.link/globalAccount?adj_t=17ibyz2m&adj_fallback=https%3A%2F%2Fgointer.app%2FglobalAccount&adj_redirect_macos=https%3A%2F%2Fgointer.app%2FglobalAccount&adj_adgroup=crossborder&adj_creative=globalaccount'

  const [ isOpen, setIsOpen ] = useState(false)
  const [ isMobile, setIsMobile ] = useState(true)

  const openModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='Escaneie o QR Code, acesse o app e abra sua Global Account'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para a Global Account em nosso app.'
        qrCode={QrCode}
      />
    </Modal>
  )

  // Abrir modal com url
  const clearLocation = (location: string) => {
    const url = new URL(location)
    const modal = url.searchParams.get('modalGlobal')
    return modal
  }

  useLayoutEffect(() => {
    setIsMobile(WIDTH_MD > width)
  }, [ width ])

  useEffect(() => {
    const location = clearLocation(window.location.href)
    if (location) {
      setIsOpen(true)
    }
  }, [ ])

  return (
    <Content>
      <Layout pageContext={pageContextJSON}>
        {openModal}
        <Hero setIsOpen={setIsOpen} isMobile={isMobile} oneLink={oneLink} />
        <DollarAccount setIsOpen={setIsOpen} isMobile={isMobile} oneLink={oneLink} />
        <SecureYourCard setIsOpen={setIsOpen} isMobile={isMobile} oneLink={oneLink} />
        <GlobalAccountAdvantages setIsOpen={setIsOpen} isMobile={isMobile} oneLink={oneLink} />
        <YourReaisAreWorthMoreDollars />
        <FacilityAndSecurity />
        <HowToOpenYourGlobalAccount setIsOpen={setIsOpen} isMobile={isMobile} oneLink={oneLink} />
        <GlobalShopping setIsOpen={setIsOpen} isMobile={isMobile} oneLink={oneLink} />
        <InternationalGiftCards setIsOpen={setIsOpen} isMobile={isMobile} oneLink={oneLink} />
        <Disclaimer />
        <Faq />
      </Layout>
    </Content>
  )
}

export default GlobalAccount
